<template>
	<v-dialog v-model="dialogDelete" max-width="500px">
		<v-card>
			<v-card-title>{{ title }}</v-card-title>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn color="grey" dark @click="cancel">Cancel</v-btn>
				<v-btn color="red darken-2" dark @click="ok">OK</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	name: 'DeleteModal',

	props: {
		dialogDelete: {
			type: Boolean,
			required: true,
			default: false,
		},
		title: {
			type: String,
			required: true,
			default: 'Delete',
		},
	},
	data() {
		return {
			//
		};
	},
	methods: {
		cancel() {
			this.$emit('cancel');
		},
		ok() {
			this.$emit('ok');
		},
	},
};
</script>

<style lang="scss" scoped>
.v-card__title {
	word-break: normal !important;
}
</style>
